/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import facebookIcon from '../../assets/icons/icon-facebook-50.png';
import instagramIcon from '../../assets/icons/icon-instagram-50.png';
import tikTokIcon from '../../assets/icons/icon-tik-tok-50.png';

export const RENDER_NEWSLETTER = 'render_newsletter';

export const NEWSLETTER_COLUMN = {
    title: __('Newsletter'),
    columnActiveKey: 'newsletterActive',
    items: [
        {
            render: RENDER_NEWSLETTER,
        },
    ],
};

export const COLUMN_MAP = [
    {
        title: __('About'),
        items: [
            {
                href: '/about-us',
                title: __('About Us'),
            },
            {
                href: '/our-materials',
                title: __('Our Materials'),
            },
        ],
    },
    {
        title: __('Terms & Conditions'),
        items: [
            {
                href: '/privacy-policy',
                title: __('Privacy Policy'),
            },
            {
                href: '/shipping-policy',
                title: __('Shipping Policy'),
            },
            {
                href: '/refund-policy',
                title: __('Refund Policy'),
            },
            {
                href: '/terms-and-conditions',
                title: __('Terms of use'),
            },
            {
                href: '/disclaimer-policy',
                title: __('Disclaimer'),
            },
        ],
    },
    {
        title: __('Help'),
        items: [
            {
                href: '/contact',
                title: __('Contact us'),
            },
            {
                href: '/faqs',
                title: __('FAQs'),
            },
            {
                href: '/installation-guide',
                title: __('Fitting Guide'),
            },
            {
                href: '/material-maintain',
                title: __('Material Maintain'),
            },
            {
                href: '/payment-method',
                title: __('Payment Method'),
            },
        ],
    },
    {
        title: __('Follow'),
        isItemsHorizontal: true,
        items: [
            {
                href: 'https://www.tiktok.com/#/',
                src: tikTokIcon,
                title: __('TikTok'),
            },
            {
                href: 'https://www.facebook.com/#/',
                src: facebookIcon,
                title: __('Facebook'),
            },
            {
                href: 'https://www.instagram.com/meqwad.qa/',
                src: instagramIcon,
                title: __('Instagram'),
            },
        ],
    },
    NEWSLETTER_COLUMN,
];
