import ContentWrapper from 'Component/ContentWrapper';
import Image from 'Component/Image';
import { FooterComponent as SourceFooter } from 'SourceComponent/Footer/Footer.component';
import { ReactElement } from 'Type/Common.type';

import './Footer.override.style';

export * from 'SourceComponent/Footer/Footer.component';

/** @namespace Pwa/Component/Footer/Component */
export class FooterComponent extends SourceFooter {
    renderColumnItemContent(src?: string, title?: string): ReactElement {
        if (!src) {
            return title;
        }

        return (
            <Image
              mix={ { block: 'Footer', elem: 'ColumnItemImage' } }
              src={ src }
            />
        );
    }

    renderCopyrightContent(): ReactElement {
        const { copyright } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <span block="Footer" elem="Copyright">
                    { copyright }
                    { ' Powered by ' }
                    { /* eslint-disable-next-line react/forbid-elements */ }
                    <a href="https://meqwad.com">
                        Meqwad
                    </a>
                </span>
            </ContentWrapper>
        );
    }
}

export default FooterComponent;
